<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import {
    BButton,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BModal,
    BPagination,
    BRow,
    BTable,
    VBTooltip
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';
import pasteImageFileAgent from '@/mixins/pasteImageFileAgent';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'TournamentList',
    components: {
        ProgressLinear,
        PageTitle,
        vSelect,
        BButton,
        BTable,
        BModal,
        flatPickr,
        BCol,
        BFormCheckbox,
        BFormGroup,
        BRow,
        BFormInput,
        BPagination,
        VBTooltip
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    mixins: [CRUDPageMixin, pasteImageFileAgent],
    data() {
        return {
            items: [],
            merchantOptions: null,
            productOptions: null,
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                type: '',
                position: 0,
                start_date: null,
                end_date: null,
                products: null,
                is_published: false
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getTournaments();
            },
            deep: true,
            immediate: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page || 1;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },
        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    methods: {
        checkLang,

        async getTournaments() {
            const body = {
                merchant: MERCHANT_ID,
                page: this.pagination.current || 1,
                limit: this.pagination.perPage || 10
            };
            await api.tournament
                .fetchAllTournaments(body)
                .then((response) => {
                    this.items = response.data.result;
                    this.pagination = response.data.pagination;

                    if (this.pagination.current > this.pagination.totalItem) {
                        this.pagination.current = 1;
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        changeAppID(appID) {
            this.activeAppID = appID;
            this.getTournaments();
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getTournaments();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean,

        changeRoute(id) {
            this.$router.push({ name: 'tournament-winners', params: { tournament_id: id } });
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('tournament.tournaments_title') }}</template>
        </page-title>

        <!--  TABLE  -->
        <div class="card">
            <progress-linear v-if="requestPending" />
            <b-table
                v-else
                :fields="fields"
                :items="items"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(id)="{ item }">
                    {{ item.id }}
                </template>
                <template #cell(name)="{ item }">
                    <div style="min-width: 200px">
                        {{ checkLang(item.name) }}
                    </div>
                </template>

                <template #cell(actions)="{ item }">
                    <div class="d-flex justify-content-end">
                        <b-button
                            v-if="hasAccess('tournament_tasks', 'view')"
                            v-b-tooltip.hover.top="`${$t('tournament.winners')}`"
                            class="btn-icon"
                            variant="success"
                            @click="changeRoute(item.id)"
                        >
                            <feather-icon icon="ArrowRightCircleIcon" size="18" />
                        </b-button>
                    </div>
                </template>
            </b-table>
        </div>

        <!--  CREATE & UPDATE MODALS  -->
        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="true"
            :title="updatingItem ? $t('edit.tournament') : $t('add.tournament')"
            cancel-variant="danger"
            centered
            size="xl"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    NAME[UZ]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.title') }} [UZ] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [UZ]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.uz"
                                    :placeholder="`${$t('enter.title')} [UZ]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME[RU]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.title') }} [RU] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [RU]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.ru"
                                    :placeholder="`${$t('enter.title')} [RU]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    NAME[EN]    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.title') }} [EN] </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.title')} [EN]`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name.en"
                                    :placeholder="`${$t('enter.title')} [EN]`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    POSITION    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.position') }} </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.position')}`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.position"
                                    :placeholder="`${$t('enter.position')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--    TYPE    -->
                    <b-col cols="4">
                        <label> {{ $t('titles.type') }} </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="`${$t('titles.type')}`"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.type"
                                    :placeholder="`${$t('enter.type')}`"
                                    :state="errors.length > 0 ? false : null"
                                    size="md"
                                />
                            </b-form-group>
                            <span class="validation__red">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-col>

                    <!--     PRODUCTS     -->
                    <b-col cols="12" style="z-index: 12312">
                        <label>{{ $t('titles.products') }}</label>
                        <v-select
                            v-model="dataForModal.products"
                            :dense="true"
                            :multiple="true"
                            :options="productOptions"
                            label="name"
                        />
                    </b-col>

                    <!--    START DATE    -->
                    <b-col cols="4">
                        <label>{{ $t('titles.start_date') }}</label>
                        <!--                        <b-form-datepicker-->
                        <!--                            v-model="dataForModal.date"-->
                        <!--                            class="mb-1"-->
                        <!--                            style="z-index: 99"-->
                        <!--                        />-->
                        <div class="input-group">
                            <flat-pickr
                                v-model="dataForModal.start_date"
                                :config="{ enableTime: true, allowInput: true, time_24hr: true }"
                                :placeholder="`${$t('choose.date')}`"
                                class="mb-1 form-control"
                                name="date"
                            />
                        </div>
                    </b-col>

                    <!--    END DATE    -->
                    <b-col cols="4">
                        <label>{{ $t('titles.end_date') }}</label>
                        <!--                        <b-form-datepicker-->
                        <!--                            v-model="dataForModal.date"-->
                        <!--                            class="mb-1"-->
                        <!--                            style="z-index: 99"-->
                        <!--                        />-->
                        <div class="input-group">
                            <flat-pickr
                                v-model="dataForModal.end_date"
                                :config="{ enableTime: true, allowInput: true, time_24hr: true }"
                                :placeholder="`${$t('choose.date')}`"
                                class="mb-1 form-control"
                                name="date"
                            />
                        </div>
                    </b-col>

                    <!--    IS PUBLISHED    -->
                    <b-col cols="4">
                        <label for="is_published">{{ $t('titles.is_published') }}</label>
                        <b-form-checkbox id="is_published" v-model="dataForModal.is_published" />
                    </b-col>
                </b-row>
            </ValidationObserver>

            <template #modal-footer>
                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">
                    {{ $t('titles.add_more') }}
                </b-form-checkbox>
                <b-button variant="success" @click="submitModal">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>

        <!--  PAGINATION  -->
        <b-row>
            <b-col class="offset-3" cols="9">
                <b-pagination
                    v-if="showPagination"
                    v-model="pagination.current"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalItem"
                    class="float-right mr-4 mb-0"
                    first-number
                    last-number
                />
            </b-col>
        </b-row>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    height: 100px;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.table-img {
    width: 60px;
    height: 60px;
    background-color: #999999;
    border-radius: 0.25rem;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
    }
}
</style>
